import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox } from "vue";
import _imports_0 from '../../../public/expand.png';
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  style: {
    "background": "#FBFBFB",
    "height": "44px"
  }
};
const _hoisted_3 = {
  style: {
    "float": "right",
    "padding": "0px 14px",
    "height": "44px",
    "display": "flex",
    "flex-direction": "row",
    "align-items": "center"
  }
};
const _hoisted_4 = {
  style: {
    "margin": "14px"
  },
  class: "activeOrderClass"
};
const _hoisted_5 = {
  class: "floatleft systemfont fontweight6 fontsize16",
  style: {
    "line-height": "22px",
    "padding": "14px 12px",
    "color": "#1C1E41"
  }
};
const _hoisted_6 = {
  class: "titlekpje floatright",
  style: {
    "padding": "14px 12px"
  }
};
const _hoisted_7 = {
  key: 0,
  class: "systemfont fontweight4 fontsize12 colorblue line18 aligncenter"
};
const _hoisted_8 = {
  class: "systemfont fontweight6 fontsize16 colorblue aligncenter",
  style: {
    "line-height": "22px"
  }
};
const _hoisted_9 = {
  class: "systemfont fontweight6 fontsize10 colorblue line16 aligncenter"
};
const _hoisted_10 = {
  style: {
    "padding": "14px 12px"
  }
};
const _hoisted_11 = {
  style: {
    "height": "20px"
  }
};
const _hoisted_12 = {
  class: "floatleft systemfont fontweight6 fontsize14 colorsmblack line20"
};
const _hoisted_13 = {
  class: "titlekpje floatright"
};
const _hoisted_14 = {
  style: {
    "height": "20px",
    "padding-top": "8px"
  }
};
const _hoisted_15 = {
  class: "sectitle systemfont"
};
const _hoisted_16 = {
  style: {
    "height": "20px",
    "padding-top": "8px"
  }
};
const _hoisted_17 = {
  style: {
    "float": "left",
    "width": "57%"
  }
};
const _hoisted_18 = {
  class: "innertext floatright systemfont aligncenter",
  style: {
    "width": "45%"
  }
};
const _hoisted_19 = {
  style: {
    "float": "right",
    "width": "43%"
  }
};
const _hoisted_20 = {
  style: {
    "height": "20px",
    "padding-top": "8px"
  }
};
const _hoisted_21 = {
  style: {
    "float": "left",
    "width": "57%"
  }
};
const _hoisted_22 = {
  class: "innertext floatright systemfont aligncenter",
  style: {
    "width": "45%"
  }
};
const _hoisted_23 = {
  style: {
    "float": "right",
    "width": "43%"
  }
};
const _hoisted_24 = {
  class: "sectitle systemfont"
};
const _hoisted_25 = {
  style: {
    "height": "20px",
    "padding-top": "8px"
  }
};
const _hoisted_26 = {
  style: {
    "float": "left",
    "width": "65%"
  }
};
const _hoisted_27 = {
  key: 0,
  class: "innertext systemfont"
};
const _hoisted_28 = {
  key: 1,
  class: "innertext systemfont"
};
const _hoisted_29 = {
  style: {
    "float": "right",
    "width": "35%"
  }
};
const _hoisted_30 = {
  style: {
    "background": "#FBFBFB",
    "border": "1px solid #EDEDF0",
    "border-top": "none",
    "border-bottom-left-radius": "12px",
    "border-bottom-right-radius": "12px"
  }
};
const _hoisted_31 = {
  style: {
    "margin": "30px 14px 0 14px",
    "align-items": "center",
    "height": "22px"
  }
};
const _hoisted_32 = {
  class: "floatleft systemfont fontweight6 fontsize16 line22",
  style: {
    "color": "#1C1E41"
  }
};
const _hoisted_33 = {
  key: 0
};
const _hoisted_34 = {
  style: {
    "position": "absolute",
    "top": "-20px",
    "left": "11px",
    "overflow": "hidden",
    "color": "#A4A5B3",
    "font-weight": "400",
    "font-size": "10px",
    "line-height": "16px"
  }
};
const _hoisted_35 = {
  key: 1,
  style: {
    "position": "absolute",
    "top": "-11px",
    "left": "11px",
    "overflow": "hidden",
    "color": "#A4A5B3"
  }
};
const _hoisted_36 = {
  style: {
    "margin": "30px 14px 0 14px",
    "align-items": "center",
    "height": "22px"
  }
};
const _hoisted_37 = {
  class: "floatleft systemfont fontweight6 fontsize16 line22",
  style: {
    "color": "#1C1E41"
  }
};
const _hoisted_38 = {
  key: 0,
  style: {
    "margin": "12px 14px",
    "align-items": "center",
    "height": "40px"
  }
};
const _hoisted_39 = {
  style: {
    "float": "left",
    "width": "50%"
  }
};
const _hoisted_40 = {
  style: {
    "float": "right",
    "width": "50%"
  }
};
const _hoisted_41 = {
  key: 1,
  style: {
    "margin": "12px 14px",
    "align-items": "center",
    "height": "40px"
  }
};
const _hoisted_42 = {
  style: {
    "float": "left",
    "width": "50%"
  }
};
const _hoisted_43 = {
  style: {
    "float": "right",
    "width": "50%"
  }
};
const _hoisted_44 = {
  key: 0
};
const _hoisted_45 = {
  style: {
    "position": "absolute",
    "top": "-20px",
    "left": "11px",
    "overflow": "hidden",
    "color": "#A4A5B3",
    "font-weight": "400",
    "font-size": "10px",
    "line-height": "16px"
  }
};
const _hoisted_46 = {
  key: 1,
  style: {
    "position": "absolute",
    "top": "-11px",
    "left": "11px",
    "overflow": "hidden",
    "color": "#A4A5B3"
  }
};
const _hoisted_47 = {
  key: 1
};
const _hoisted_48 = {
  class: "outerr"
};
const _hoisted_49 = {
  class: "errmsg systemfont"
};
const _hoisted_50 = {
  class: "outerr"
};
const _hoisted_51 = {
  class: "errmsg systemfont"
};
const _hoisted_52 = {
  class: "outerr"
};
const _hoisted_53 = {
  class: "errmsg systemfont"
};
const _hoisted_54 = {
  class: "outerr"
};
const _hoisted_55 = {
  class: "errmsg systemfont"
};
const _hoisted_56 = {
  key: 2
};
const _hoisted_57 = {
  class: "outerr"
};
const _hoisted_58 = {
  class: "errmsg systemfont"
};
const _hoisted_59 = {
  class: "outerr"
};
const _hoisted_60 = {
  class: "errmsg systemfont"
};
const _hoisted_61 = {
  class: "outerr"
};
const _hoisted_62 = {
  class: "errmsg systemfont"
};
const _hoisted_63 = {
  class: "outerr"
};
const _hoisted_64 = {
  class: "errmsg systemfont"
};
const _hoisted_65 = {
  class: "outerr"
};
const _hoisted_66 = {
  class: "errmsg systemfont"
};
const _hoisted_67 = {
  key: 3
};
const _hoisted_68 = {
  class: "outerr"
};
const _hoisted_69 = {
  class: "errmsg systemfont"
};
const _hoisted_70 = {
  class: "outerr"
};
const _hoisted_71 = {
  class: "errmsg systemfont"
};
const _hoisted_72 = {
  class: "outerr"
};
const _hoisted_73 = {
  class: "errmsg systemfont"
};
const _hoisted_74 = {
  key: 0
};
const _hoisted_75 = {
  class: "outerr"
};
const _hoisted_76 = {
  class: "errmsg systemfont"
};
const _hoisted_77 = {
  key: 1
};
const _hoisted_78 = {
  class: "outerr"
};
const _hoisted_79 = {
  class: "errmsg systemfont"
};
const _hoisted_80 = {
  style: {
    "margin": "30px 14px 0 14px",
    "align-items": "center"
  }
};
const _hoisted_81 = {
  class: "systemfont fontweight6 fontsize16 line22",
  style: {
    "color": "#1C1E41"
  }
};
const _hoisted_82 = {
  style: {
    "margin": "4px 14px 0 14px",
    "align-items": "center"
  }
};
const _hoisted_83 = {
  class: "systemfont fontweight4 fontsize14 line20",
  style: {
    "color": "#707187"
  }
};
const _hoisted_84 = {
  style: {
    "margin": "12px 14px",
    "align-items": "center",
    "height": "40px"
  }
};
const _hoisted_85 = {
  style: {
    "float": "left",
    "width": "50%"
  }
};
const _hoisted_86 = {
  style: {
    "float": "right",
    "width": "50%"
  }
};
const _hoisted_87 = {
  class: "outerr"
};
const _hoisted_88 = {
  class: "errmsg systemfont"
};
const _hoisted_89 = {
  class: "outerr"
};
const _hoisted_90 = {
  class: "errmsg systemfont"
};
const _hoisted_91 = {
  style: {
    "margin": "12px 14px 0 14px",
    "align-items": "center"
  }
};
const _hoisted_92 = {
  class: "systemfont fontweight4 fontsize14 line20",
  style: {
    "color": "#707187"
  }
};
const _hoisted_93 = {
  style: {
    "margin": "0 14px"
  }
};
const _hoisted_94 = {
  style: {
    "float": "left",
    "width": "16px"
  }
};
const _hoisted_95 = {
  class: "systemfont fontweight4 fontsize14 colorsmblack line20",
  style: {
    "margin-left": "25px"
  }
};
const _hoisted_96 = {
  style: {
    "margin": "30px 14px"
  }
};
const _hoisted_97 = ["disabled"];
const _hoisted_98 = {
  class: "errtitle systemfont"
};
const _hoisted_99 = {
  class: "errcont systemfont"
};
const _hoisted_100 = {
  class: "poptitleab systemfont"
};
const _hoisted_101 = {
  style: {
    "position": "absolute",
    "right": "0",
    "bottom": "0",
    "left": "0",
    "top": "54px",
    "overflow": "auto"
  }
};
const _hoisted_102 = {
  class: "poptitle systemfont"
};
const _hoisted_103 = {
  class: "popcontent systemfont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading");
  const _component_van_collapse_item = _resolveComponent("van-collapse-item");
  const _component_van_collapse = _resolveComponent("van-collapse");
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_dropdown_item = _resolveComponent("van-dropdown-item");
  const _component_van_dropdown_menu = _resolveComponent("van-dropdown-menu");
  const _component_InputField = _resolveComponent("InputField");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_FapiaoFAQ = _resolveComponent("FapiaoFAQ");
  return _openBlock(), _createElementBlock("div", null, [$data.showLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Loading)])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: _normalizeClass($data.languagecn),
    onClick: _cache[0] || (_cache[0] = $event => $options.onChangeLanguage('zh'))
  }, "中", 2), _cache[53] || (_cache[53] = _createElementVNode("div", {
    class: "language_middle"
  }, null, -1)), _createElementVNode("div", {
    class: _normalizeClass($data.languageen),
    onClick: _cache[1] || (_cache[1] = $event => $options.onChangeLanguage('en'))
  }, "EN", 2)])]), _createElementVNode("div", _hoisted_4, [_cache[60] || (_cache[60] = _createElementVNode("div", {
    class: "fixed-top-bar"
  }, null, -1)), _createVNode(_component_van_collapse, {
    modelValue: $setup.activeOrders,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.activeOrders = $event),
    ref: "collapse"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_collapse_item, {
      name: "1"
    }, {
      title: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('AppPage.Fapiaoamount')), 1)]),
      "right-icon": _withCtx(() => _cache[54] || (_cache[54] = [])),
      value: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, [$data.fapiaoAmount.includes('-') ? (_openBlock(), _createElementBlock("span", _hoisted_7, "-")) : _createCommentVNode("", true), _cache[55] || (_cache[55] = _createElementVNode("span", {
        class: "systemfont fontweight4 fontsize12 colorblue line18 aligncenter"
      }, "¥", -1)), _createElementVNode("span", _hoisted_8, _toDisplayString($data.fapiaoAmount.split('.')[0].replace('-', '')), 1), _createElementVNode("span", _hoisted_9, _toDisplayString('.' + $data.fapiaoAmount.split('.')[1]), 1)])])]),
      default: _withCtx(() => [_cache[58] || (_cache[58] = _createElementVNode("hr", {
        class: "tophr"
      }, null, -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.orders, order => {
        return _openBlock(), _createElementBlock("div", {
          key: order
        }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('AppPage.Ordernumber')) + "：" + _toDisplayString(order.sn), 1), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", null, [order.sumPrice.includes('-') ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass($data.dollorcss)
        }, "-", 2)) : _createCommentVNode("", true), _createElementVNode("span", {
          class: _normalizeClass($data.dollorcss)
        }, "¥", 2), _createElementVNode("span", {
          class: _normalizeClass($data.price1css)
        }, _toDisplayString(order.sumPrice.split('.')[0].replace('-', '')), 3), _createElementVNode("span", {
          class: _normalizeClass($data.price2css)
        }, _toDisplayString('.' + order.sumPrice.split('.')[1]), 3)])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", {
          class: _normalizeClass($data.titlecss)
        }, _toDisplayString(_ctx.$t('AppPage.Transtime')), 3), _createElementVNode("div", {
          class: _normalizeClass($data.valuecss)
        }, _toDisplayString(order.insertDate), 3)]), _cache[56] || (_cache[56] = _createElementVNode("hr", {
          class: "sechr"
        }, null, -1)), _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('AppPage.Transdetails')), 1), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", {
          class: _normalizeClass($data.titlecss),
          style: {
            "width": "55%"
          }
        }, _toDisplayString(_ctx.$t('AppPage.Item')), 3), _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('AppPage.Amount')), 1)]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", {
          class: _normalizeClass($data.titlecss)
        }, _toDisplayString(_ctx.$t('AppPage.Price')), 3), _createElementVNode("div", {
          class: _normalizeClass($data.valuecss)
        }, _toDisplayString(_ctx.$t('AppPage.Issuing')), 3)])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.goodsDetails, goodsDetail => {
          return _openBlock(), _createElementBlock("div", {
            key: goodsDetail
          }, [_createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("div", {
            class: _normalizeClass($data.titlecss),
            style: {
              "width": "55%",
              "overflow": "hidden"
            }
          }, _toDisplayString(goodsDetail.name), 3), _createElementVNode("div", _hoisted_22, _toDisplayString(goodsDetail.num), 1)]), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", {
            class: _normalizeClass($data.titlecss)
          }, [_createElementVNode("div", null, [goodsDetail.price.includes('-') ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass($data.dollorcss)
          }, "-", 2)) : _createCommentVNode("", true), _createElementVNode("span", {
            class: _normalizeClass($data.dollorcss)
          }, "¥", 2), _createElementVNode("span", {
            class: _normalizeClass($data.price1css)
          }, _toDisplayString(goodsDetail.price.split('.')[0].replace('-', '')), 3), _createElementVNode("span", {
            class: _normalizeClass($data.price2css)
          }, _toDisplayString('.' + goodsDetail.price.split('.')[1]), 3)])], 2), goodsDetail.iskp == '是' ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass($data.valuecss)
          }, _toDisplayString(_ctx.$t('AppPage.yes')), 3)) : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass($data.valuecss)
          }, _toDisplayString(_ctx.$t('AppPage.no')), 3))])])]);
        }), 128)), _cache[57] || (_cache[57] = _createElementVNode("hr", {
          class: "sechr"
        }, null, -1)), _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('AppPage.Payment')), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.payDetails, payDetail => {
          return _openBlock(), _createElementBlock("div", {
            key: payDetail
          }, [_createElementVNode("div", _hoisted_25, [_createElementVNode("div", _hoisted_26, [this.$store.state.lang == 'zh' ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(payDetail.paymethodcn), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(payDetail.paymethoden), 1))]), _createElementVNode("div", _hoisted_29, [_createElementVNode("div", {
            class: _normalizeClass($data.titlecss)
          }, [_createElementVNode("div", null, [payDetail.payprice.includes('-') ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass($data.dollorcss)
          }, "-", 2)) : _createCommentVNode("", true), _createElementVNode("span", {
            class: _normalizeClass($data.dollorcss)
          }, "¥", 2), _createElementVNode("span", {
            class: _normalizeClass($data.price1css)
          }, _toDisplayString(payDetail.payprice.split('.')[0].replace('-', '')), 3), _createElementVNode("span", {
            class: _normalizeClass($data.price2css)
          }, _toDisplayString('.' + payDetail.payprice.split('.')[1]), 3)])], 2), payDetail.iskp == '是' ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass($data.valuecss)
          }, _toDisplayString(_ctx.$t('AppPage.yes')), 3)) : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass($data.valuecss)
          }, _toDisplayString(_ctx.$t('AppPage.no')), 3))])])]);
        }), 128))])]);
      }), 128))]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_30, [_cache[59] || (_cache[59] = _createElementVNode("hr", {
    class: "tophr"
  }, null, -1)), _withDirectives(_createElementVNode("div", {
    class: "showdetail",
    onClick: _cache[3] || (_cache[3] = (...args) => $options.onclickdown && $options.onclickdown(...args))
  }, [_createElementVNode("span", {
    class: _normalizeClass($data.showdetailcss)
  }, _toDisplayString(_ctx.$t('AppPage.Details')), 3), _createVNode(_component_van_icon, {
    name: "arrow-down",
    style: {
      "color": "#0064F0",
      "padding": "2px"
    }
  })], 512), [[_vShow, $data.seekpjedown]]), _withDirectives(_createElementVNode("div", {
    class: "showdetail",
    onClick: _cache[4] || (_cache[4] = (...args) => $options.onclickup && $options.onclickup(...args))
  }, [_createElementVNode("span", {
    class: _normalizeClass($data.showdetailcss)
  }, _toDisplayString(_ctx.$t('AppPage.Collapse')), 3), _createVNode(_component_van_icon, {
    name: "arrow-up",
    style: {
      "color": "#0064F0",
      "padding": "2px"
    }
  })], 512), [[_vShow, $data.seekpjeup]])])]), _createElementVNode("div", _hoisted_31, [_createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t('AppPage.Fapiaotype')), 1)]), _createElementVNode("div", {
    class: "classSHDR",
    style: _normalizeStyle({
      width: $data.qywidth,
      margin: '12px 14px'
    })
  }, [_createVNode(_component_van_dropdown_menu, {
    overlay: false,
    class: _normalizeClass($data.fplxBorder)
  }, {
    default: _withCtx(() => [_createVNode(_component_van_dropdown_item, {
      modelValue: $data.invoiceTypeVal,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.invoiceTypeVal = $event),
      options: $data.invoiceTypeOption,
      onChange: $options.clickChangeLx,
      onOpen: $options.changeOpenBorderLx,
      onClose: $options.changeCloseBorderLx
    }, {
      title: _withCtx(() => [$data.invoiceTypeVal ? (_openBlock(), _createElementBlock("span", _hoisted_33, [_createElementVNode("span", _hoisted_34, _toDisplayString($data.fplxphi), 1), _createElementVNode("span", {
        style: _normalizeStyle([{
          maxWidth: $data.spanwidth
        }, {
          "position": "absolute",
          "top": "-4px",
          "left": "11px",
          "overflow": "hidden",
          "font-weight": "400",
          "font-size": "16px",
          "line-height": "22px",
          "color": "#494B67",
          "font-family": "'TWDC-Heavy'"
        }])
      }, _toDisplayString($data.invoiceType), 5)])) : (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString($data.fplxphi), 1)), _createElementVNode("img", {
        src: _imports_0,
        style: _normalizeStyle([{
          "position": "absolute",
          "top": "-10px",
          "width": "20px",
          "height": "20px"
        }, {
          left: $data.commonwidth
        }])
      }, null, 4)]),
      _: 1
    }, 8, ["modelValue", "options", "onChange", "onOpen", "onClose"])]),
    _: 1
  }, 8, ["class"])], 4), _createElementVNode("div", _hoisted_36, [_createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t('AppPage.Fapiaoinformation')), 1), _createElementVNode("div", {
    class: "floatright systemfont fontweight3 fontsize14 colorblue line22",
    onClick: _cache[6] || (_cache[6] = $event => this.showqusBottom = true)
  }, _toDisplayString(_ctx.$t('AppPage.FapiaoFAQ')), 1)]), this.$store.state.lang == 'zh' ? (_openBlock(), _createElementBlock("div", _hoisted_38, [_createElementVNode("div", _hoisted_39, [_createElementVNode("div", {
    class: _normalizeClass(["choseonebtn systemfont", [{
      'btnblue': $data.chosenzh == 1
    }, {
      'btnclack': $data.chosenzh == 0
    }]]),
    style: {
      "margin-right": "6px"
    },
    onClick: _cache[7] || (_cache[7] = $event => $options.onSwitchUser('qy'))
  }, _toDisplayString(_ctx.$t('AppPage.Institution')), 3)]), _createElementVNode("div", _hoisted_40, [_createElementVNode("div", {
    class: _normalizeClass(["choseonebtn systemfont", [{
      'btnblue': $data.chosenzh == 0
    }, {
      'btnclack': $data.chosenzh == 1
    }]]),
    style: {
      "margin-left": "6px"
    },
    onClick: _cache[8] || (_cache[8] = $event => $options.onSwitchUser('gr'))
  }, _toDisplayString(_ctx.$t('AppPage.Personal')), 3)])])) : (_openBlock(), _createElementBlock("div", _hoisted_41, [_createElementVNode("div", _hoisted_42, [_createElementVNode("div", {
    class: _normalizeClass(["choseonebtn systemfont", [{
      'btnbluen': $data.chosenzh == 1
    }, {
      'btnclack': $data.chosenzh == 0
    }]]),
    style: {
      "margin-right": "6px"
    },
    onClick: _cache[9] || (_cache[9] = $event => $options.onSwitchUser('qy'))
  }, _toDisplayString(_ctx.$t('AppPage.Institution')), 3)]), _createElementVNode("div", _hoisted_43, [_createElementVNode("div", {
    class: _normalizeClass(["choseonebtnen systemfont", [{
      'btnblue': $data.chosenzh == 0
    }, {
      'btnclack': $data.chosenzh == 1
    }]]),
    style: {
      "margin-left": "6px"
    },
    onClick: _cache[10] || (_cache[10] = $event => $options.onSwitchUser('gr'))
  }, [_createTextVNode(_toDisplayString(_ctx.$t('AppPage.Personalx')), 1), _cache[61] || (_cache[61] = _createElementVNode("br", null, null, -1)), _createTextVNode(_toDisplayString(_ctx.$t('AppPage.Personaly')), 1)], 2)])])), _withDirectives(_createElementVNode("div", null, [$data.isSHDR ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "classSHDR",
    style: _normalizeStyle({
      width: $data.qywidth,
      margin: '0 14px'
    })
  }, [_createVNode(_component_van_dropdown_menu, {
    overlay: false,
    class: _normalizeClass($data.shdrBorder)
  }, {
    default: _withCtx(() => [_createVNode(_component_van_dropdown_item, {
      modelValue: $data.buyerNameqyval,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => $data.buyerNameqyval = $event),
      options: $data.customerListOption,
      onChange: $options.clickChangeQy,
      onOpen: $options.changeOpenBorder,
      onClose: $options.changeCloseBorder
    }, {
      title: _withCtx(() => [$data.buyerNameqyval ? (_openBlock(), _createElementBlock("span", _hoisted_44, [_createElementVNode("span", _hoisted_45, _toDisplayString($data.gfmcphi), 1), _createElementVNode("span", {
        style: _normalizeStyle([{
          maxWidth: $data.spanwidth
        }, {
          "position": "absolute",
          "top": "-4px",
          "left": "11px",
          "overflow": "hidden",
          "font-weight": "400",
          "font-size": "16px",
          "line-height": "22px",
          "color": "#494B67"
        }])
      }, _toDisplayString($data.kpinform.buyerNameqy), 5)])) : (_openBlock(), _createElementBlock("span", _hoisted_46, _toDisplayString($data.gfmcphi), 1)), _createElementVNode("img", {
        src: _imports_0,
        style: _normalizeStyle([{
          "position": "absolute",
          "top": "-10px",
          "width": "20px",
          "height": "20px"
        }, {
          left: $data.commonwidth
        }])
      }, null, 4)]),
      _: 1
    }, 8, ["modelValue", "options", "onChange", "onOpen", "onClose"])]),
    _: 1
  }, 8, ["class"])], 4)) : (_openBlock(), _createElementBlock("div", _hoisted_47, [_createVNode(_component_InputField, {
    upfieldid: "buyerNameqy",
    "model-value": $data.kpinform.buyerNameqy,
    "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => $data.kpinform.buyerNameqy = $event),
    placeholder: $data.gfmcph,
    placeholderi: $data.gfmcphi,
    outstyle: $data.gfmcqysty,
    "onUpdate:outstyle": _cache[13] || (_cache[13] = $event => $data.gfmcqysty = $event)
  }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"])])), _withDirectives(_createElementVNode("div", _hoisted_48, [_createElementVNode("span", _hoisted_49, _toDisplayString(this.$t('ErrMsgAppPage.title')), 1)], 512), [[_vShow, $data.gfmcmsg]]), _withDirectives(_createElementVNode("div", _hoisted_50, [_createElementVNode("span", _hoisted_51, _toDisplayString(this.$t('ErrMsgAppPage.cortitle')), 1)], 512), [[_vShow, $data.gfmcmsgn]]), _cache[71] || (_cache[71] = _createElementVNode("div", {
    style: {
      "margin": "12px"
    }
  }, null, -1)), _createVNode(_component_InputField, {
    upfieldid: "taxNo",
    "model-value": $data.kpinform.taxNo,
    "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => $data.kpinform.taxNo = $event),
    placeholder: $data.nsrsbhpla,
    placeholderi: $data.nsrsbhplai,
    outstyle: $data.nsrsbhsty,
    "onUpdate:outstyle": _cache[15] || (_cache[15] = $event => $data.nsrsbhsty = $event)
  }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_52, [_createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t('ErrMsgAppPage.regist')), 1)], 512), [[_vShow, $data.nsrsbhmsg]]), _withDirectives(_createElementVNode("div", _hoisted_54, [_createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.$t('ErrMsgAppPage.corregist')), 1)], 512), [[_vShow, $data.nsrsbhmsgn]]), $data.invoiceTypeVal == 'EC' ? (_openBlock(), _createElementBlock("div", _hoisted_56, [_createVNode(_component_van_collapse, {
    modelValue: $setup.activeInform,
    "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => $setup.activeInform = $event),
    ref: "collapse2"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_collapse_item, {
      name: "2",
      disabled: ""
    }, {
      title: _withCtx(() => _cache[62] || (_cache[62] = [])),
      "right-icon": _withCtx(() => _cache[63] || (_cache[63] = [])),
      value: _withCtx(() => _cache[64] || (_cache[64] = [])),
      default: _withCtx(() => [_cache[65] || (_cache[65] = _createElementVNode("div", {
        style: {
          "margin": "12px"
        }
      }, null, -1)), _createVNode(_component_InputField, {
        upfieldid: "compAddress",
        "model-value": $data.kpinform.compAddress,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => $data.kpinform.compAddress = $event),
        placeholder: $data.gsdzph,
        placeholderi: $data.gsdzphi,
        outstyle: $data.gsdzsty,
        "onUpdate:outstyle": _cache[17] || (_cache[17] = $event => $data.gsdzsty = $event)
      }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_57, [_createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.$t('ErrMsgAppPage.company')), 1)], 512), [[_vShow, $data.gsdzmsg]]), _cache[66] || (_cache[66] = _createElementVNode("div", {
        style: {
          "margin": "12px"
        }
      }, null, -1)), _createVNode(_component_InputField, {
        upfieldid: "bank",
        "model-value": $data.kpinform.bank,
        "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => $data.kpinform.bank = $event),
        placeholder: $data.khyhph,
        placeholderi: $data.khyhphi,
        outstyle: $data.khyhsty,
        "onUpdate:outstyle": _cache[19] || (_cache[19] = $event => $data.khyhsty = $event)
      }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_59, [_createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.$t('ErrMsgAppPage.bankdeposit')), 1)], 512), [[_vShow, $data.khyhmsg]]), _cache[67] || (_cache[67] = _createElementVNode("div", {
        style: {
          "margin": "12px"
        }
      }, null, -1)), _createVNode(_component_InputField, {
        upfieldid: "phone",
        "model-value": $data.kpinform.phone,
        "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => $data.kpinform.phone = $event),
        placeholder: $data.dhhmph,
        placeholderi: $data.dhhmphi,
        outstyle: $data.dhhmsty,
        "onUpdate:outstyle": _cache[21] || (_cache[21] = $event => $data.dhhmsty = $event)
      }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_61, [_createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.$t('ErrMsgAppPage.phone')), 1)], 512), [[_vShow, $data.dhhmmsg]]), _cache[68] || (_cache[68] = _createElementVNode("div", {
        style: {
          "margin": "12px"
        }
      }, null, -1)), _createVNode(_component_InputField, {
        upfieldid: "BankAddress",
        "model-value": $data.kpinform.BankAddress,
        "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => $data.kpinform.BankAddress = $event),
        placeholder: $data.yhzhph,
        placeholderi: $data.yhzhphi,
        outstyle: $data.yhzhsty,
        "onUpdate:outstyle": _cache[23] || (_cache[23] = $event => $data.yhzhsty = $event)
      }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_63, [_createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.$t('ErrMsgAppPage.bankaccount')), 1)], 512), [[_vShow, $data.yhzhmsg]]), _cache[69] || (_cache[69] = _createElementVNode("div", {
        style: {
          "margin": "12px"
        }
      }, null, -1)), _createVNode(_component_InputField, {
        upfieldid: "memoqy",
        "model-value": $data.kpinform.memoqy,
        "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => $data.kpinform.memoqy = $event),
        placeholder: $data.fpbzph,
        placeholderi: $data.fpbzphi,
        outstyle: $data.fpbzsty,
        "onUpdate:outstyle": _cache[25] || (_cache[25] = $event => $data.fpbzsty = $event)
      }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_65, [_createElementVNode("span", _hoisted_66, _toDisplayString(_ctx.$t('ErrMsgAppPage.remark')), 1)], 512), [[_vShow, $data.fpbzmsg]])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _withDirectives(_createElementVNode("div", {
    class: "showdetail1",
    onClick: _cache[27] || (_cache[27] = (...args) => $options.onfpxxdown && $options.onfpxxdown(...args))
  }, [_createElementVNode("span", {
    class: _normalizeClass($data.showdetailcss)
  }, _toDisplayString(_ctx.$t('AppPage.Showmoreinfo')), 3), _createVNode(_component_van_icon, {
    name: "arrow-down",
    style: {
      "color": "#0064F0",
      "padding": "2px"
    }
  })], 512), [[_vShow, $data.seekpxxdown1]]), _withDirectives(_createElementVNode("div", {
    class: "showdetail1",
    onClick: _cache[28] || (_cache[28] = (...args) => $options.onfpxxup && $options.onfpxxup(...args))
  }, [_createElementVNode("span", {
    class: _normalizeClass($data.showdetailcss)
  }, _toDisplayString(_ctx.$t('AppPage.Hidemoreinfo')), 3), _createVNode(_component_van_icon, {
    name: "arrow-up",
    style: {
      "color": "#0064F0",
      "padding": "2px"
    }
  })], 512), [[_vShow, $data.seekpxxup1]])])) : _createCommentVNode("", true), $data.invoiceTypeVal == 'BEC' || $data.invoiceTypeVal == 'BES' ? (_openBlock(), _createElementBlock("div", _hoisted_67, [_cache[70] || (_cache[70] = _createElementVNode("div", {
    style: {
      "margin": "12px"
    }
  }, null, -1)), _createVNode(_component_InputField, {
    upfieldid: "memoqy",
    "model-value": $data.kpinform.memoqy,
    "onUpdate:modelValue": _cache[29] || (_cache[29] = $event => $data.kpinform.memoqy = $event),
    placeholder: $data.fpbzph,
    placeholderi: $data.fpbzphi,
    outstyle: $data.fpbzsty,
    "onUpdate:outstyle": _cache[30] || (_cache[30] = $event => $data.fpbzsty = $event)
  }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_68, [_createElementVNode("span", _hoisted_69, _toDisplayString(_ctx.$t('ErrMsgAppPage.remark')), 1)], 512), [[_vShow, $data.fpbzmsg]])])) : _createCommentVNode("", true)], 512), [[_vShow, $data.choseqy]]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_InputField, {
    upfieldid: "buyerNamegr",
    "model-value": $data.kpinform.buyerNamegr,
    "onUpdate:modelValue": _cache[31] || (_cache[31] = $event => $data.kpinform.buyerNamegr = $event),
    placeholder: $data.gfmcph,
    placeholderi: $data.gfmcphi,
    outstyle: $data.gfmcsty,
    "onUpdate:outstyle": _cache[32] || (_cache[32] = $event => $data.gfmcsty = $event)
  }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_70, [_createElementVNode("span", _hoisted_71, _toDisplayString(this.$t('ErrMsgAppPage.title')), 1)], 512), [[_vShow, $data.gfmcgrmsg]]), _withDirectives(_createElementVNode("div", _hoisted_72, [_createElementVNode("span", _hoisted_73, _toDisplayString(this.$t('ErrMsgAppPage.cortitle')), 1)], 512), [[_vShow, $data.gfmcgrmsgn]]), $data.invoiceTypeVal == 'EC' ? (_openBlock(), _createElementBlock("div", _hoisted_74, [_createVNode(_component_van_collapse, {
    modelValue: $setup.activeInform,
    "onUpdate:modelValue": _cache[35] || (_cache[35] = $event => $setup.activeInform = $event),
    ref: "collapse1"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_collapse_item, {
      name: "3",
      disabled: ""
    }, {
      title: _withCtx(() => _cache[72] || (_cache[72] = [])),
      "right-icon": _withCtx(() => _cache[73] || (_cache[73] = [])),
      value: _withCtx(() => _cache[74] || (_cache[74] = [])),
      default: _withCtx(() => [_cache[75] || (_cache[75] = _createElementVNode("div", {
        style: {
          "margin": "12px"
        }
      }, null, -1)), _createVNode(_component_InputField, {
        upfieldid: "memogr",
        "model-value": $data.kpinform.memogr,
        "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => $data.kpinform.memogr = $event),
        placeholder: $data.fpbzph,
        placeholderi: $data.fpbzphi,
        outstyle: $data.fpbzsty,
        "onUpdate:outstyle": _cache[34] || (_cache[34] = $event => $data.fpbzsty = $event)
      }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_75, [_createElementVNode("span", _hoisted_76, _toDisplayString(_ctx.$t('ErrMsgAppPage.remark')), 1)], 512), [[_vShow, $data.fpbzmsg]])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _withDirectives(_createElementVNode("div", {
    class: "showdetail1",
    onClick: _cache[36] || (_cache[36] = (...args) => $options.onfpxxdown && $options.onfpxxdown(...args))
  }, [_createElementVNode("span", {
    class: _normalizeClass($data.showdetailcss)
  }, _toDisplayString(_ctx.$t('AppPage.Showmoreinfo')), 3), _createVNode(_component_van_icon, {
    name: "arrow-down",
    style: {
      "color": "#0064F0",
      "padding": "2px"
    }
  })], 512), [[_vShow, $data.seekpxxdown2]]), _withDirectives(_createElementVNode("div", {
    class: "showdetail1",
    onClick: _cache[37] || (_cache[37] = (...args) => $options.onfpxxup && $options.onfpxxup(...args))
  }, [_createElementVNode("span", {
    class: _normalizeClass($data.showdetailcss)
  }, _toDisplayString(_ctx.$t('AppPage.Hidemoreinfo')), 3), _createVNode(_component_van_icon, {
    name: "arrow-up",
    style: {
      "color": "#0064F0",
      "padding": "2px"
    }
  })], 512), [[_vShow, $data.seekpxxup2]])])) : _createCommentVNode("", true), $data.invoiceTypeVal == 'BEC' || $data.invoiceTypeVal == 'BES' ? (_openBlock(), _createElementBlock("div", _hoisted_77, [_cache[76] || (_cache[76] = _createElementVNode("div", {
    style: {
      "margin": "12px"
    }
  }, null, -1)), _createVNode(_component_InputField, {
    upfieldid: "memogr",
    "model-value": $data.kpinform.memogr,
    "onUpdate:modelValue": _cache[38] || (_cache[38] = $event => $data.kpinform.memogr = $event),
    placeholder: $data.fpbzph,
    placeholderi: $data.fpbzphi,
    outstyle: $data.fpbzsty,
    "onUpdate:outstyle": _cache[39] || (_cache[39] = $event => $data.fpbzsty = $event)
  }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_78, [_createElementVNode("span", _hoisted_79, _toDisplayString(_ctx.$t('ErrMsgAppPage.remark')), 1)], 512), [[_vShow, $data.fpbzmsg]])])) : _createCommentVNode("", true)], 512), [[_vShow, $data.chosegr]]), _createElementVNode("div", _hoisted_80, [_createElementVNode("div", _hoisted_81, _toDisplayString(_ctx.$t('AppPage.information')), 1)]), _createElementVNode("div", _hoisted_82, [_createElementVNode("div", _hoisted_83, _toDisplayString(_ctx.$t('AppPage.chose')), 1)]), _createElementVNode("div", _hoisted_84, [_createElementVNode("div", _hoisted_85, [_createElementVNode("div", {
    class: _normalizeClass($data.sjbtn),
    style: {
      "margin-right": "6px"
    },
    onClick: _cache[40] || (_cache[40] = $event => $options.onSwitchShou('sj'))
  }, _toDisplayString(_ctx.$t('AppPage.Mobile')), 3)]), _createElementVNode("div", _hoisted_86, [_createElementVNode("div", {
    class: _normalizeClass($data.yxbtn),
    style: {
      "margin-left": "6px"
    },
    onClick: _cache[41] || (_cache[41] = $event => $options.onSwitchShou('yx'))
  }, _toDisplayString(_ctx.$t('AppPage.Email')), 3)])]), $data.seespsj ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass($data.grsjhsty)
  }, [_createVNode(_component_van_field, {
    id: "sendPhoneid",
    onFocus: $options.focusedphone,
    onBlur: $options.unfocusedphone,
    onKeyup: $options.clearChinese,
    autocomplete: "off",
    class: "uptextfont",
    modelValue: $data.sendInform.sendPhone,
    "onUpdate:modelValue": _cache[42] || (_cache[42] = $event => $data.sendInform.sendPhone = $event),
    clearable: ""
  }, null, 8, ["onFocus", "onBlur", "onKeyup", "modelValue"]), _createElementVNode("span", {
    class: _normalizeClass([{
      'focusBlur': $data.changeIndexphone == 1
    }, {
      'focusBlurTwo': $data.changeIndexphone == 0
    }])
  }, _toDisplayString($data.grsjhphi), 3)], 2)) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_87, [_createElementVNode("span", _hoisted_88, _toDisplayString(_ctx.$t('ErrMsgAppPage.mobile')), 1)], 512), [[_vShow, $data.grsjhmsg]]), $data.seeskyx ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: _normalizeClass($data.gryxsty)
  }, [_createVNode(_component_van_field, {
    id: "sendEmailid",
    onFocus: $options.focusedemail,
    onBlur: $options.unfocusedemail,
    onKeyup: $options.clearBlank,
    autocomplete: "off",
    class: "uptextfont",
    modelValue: $data.sendInform.sendEmail,
    "onUpdate:modelValue": _cache[43] || (_cache[43] = $event => $data.sendInform.sendEmail = $event),
    clearable: ""
  }, null, 8, ["onFocus", "onBlur", "onKeyup", "modelValue"]), _createElementVNode("span", {
    class: _normalizeClass([{
      'focusBlur': $data.changeIndexemail == 1
    }, {
      'focusBlurTwo': $data.changeIndexemail == 0
    }])
  }, _toDisplayString($data.gryxphi), 3)], 2)) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_89, [_createElementVNode("span", _hoisted_90, _toDisplayString(_ctx.$t('ErrMsgAppPage.email')), 1)], 512), [[_vShow, $data.gryxmsg]]), _withDirectives(_createElementVNode("div", _hoisted_91, [_createElementVNode("div", _hoisted_92, _toDisplayString(_ctx.$t('AppPage.sendmethod')) + " " + _toDisplayString($data.final), 1)], 512), [[_vShow, $data.sendmethod]]), _cache[77] || (_cache[77] = _createElementVNode("hr", {
    style: {
      "border": "0.5px solid #EDEDF0",
      "margin": "16px 14px"
    }
  }, null, -1)), _createElementVNode("div", _hoisted_93, [_createElementVNode("div", _hoisted_94, [_withDirectives(_createElementVNode("input", {
    type: "checkbox",
    "onUpdate:modelValue": _cache[44] || (_cache[44] = $event => $data.isPrivate = $event),
    class: "checkRicon"
  }, null, 512), [[_vModelCheckbox, $data.isPrivate]])]), _createElementVNode("div", _hoisted_95, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('AppPage.Link1')), 1), _createElementVNode("a", {
    class: "colorblue",
    onClick: _cache[45] || (_cache[45] = (...args) => $options.onShowPolicy && $options.onShowPolicy(...args))
  }, _toDisplayString(_ctx.$t('AppPage.Link2')), 1), _createElementVNode("span", null, _toDisplayString(_ctx.$t('AppPage.Link3')), 1), _createElementVNode("a", {
    class: "colorblue",
    onClick: _cache[46] || (_cache[46] = (...args) => $options.onShowPolicy && $options.onShowPolicy(...args))
  }, _toDisplayString(_ctx.$t('AppPage.Link2')), 1), _createElementVNode("span", null, _toDisplayString(_ctx.$t('AppPage.Link4')), 1)])]), _createElementVNode("div", _hoisted_96, [_createElementVNode("button", {
    class: _normalizeClass($data.searchbtn),
    disabled: $data.isSubmit,
    onClick: _cache[47] || (_cache[47] = (...args) => $options.onClickSubmit && $options.onClickSubmit(...args))
  }, _toDisplayString(_ctx.$t('AppPage.Submit')), 11, _hoisted_97)]), _createVNode(_component_van_popup, {
    show: $data.showFail,
    "onUpdate:show": _cache[50] || (_cache[50] = $event => $data.showFail = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_98, _toDisplayString(_ctx.$t('ManPage.errtitle')), 1), _createElementVNode("div", _hoisted_99, _toDisplayString($data.FailErrMsg), 1), _createElementVNode("button", {
      class: _normalizeClass($data.closeyes),
      onClick: _cache[48] || (_cache[48] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancel),
      onClick: _cache[49] || (_cache[49] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closecancel')), 3)]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showqusBottom,
    "onUpdate:show": _cache[51] || (_cache[51] = $event => $data.showqusBottom = $event),
    round: "",
    closeable: "",
    position: "bottom",
    style: {
      height: '90%'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_100, _toDisplayString(_ctx.$t('ManPage.poptitle')), 1), _createElementVNode("div", _hoisted_101, [_createVNode(_component_FapiaoFAQ)])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showysBottom,
    "onUpdate:show": _cache[52] || (_cache[52] = $event => $data.showysBottom = $event),
    round: "",
    closeable: "",
    position: "bottom",
    style: {
      height: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_102, _toDisplayString(_ctx.$t('AppPage.Link2')), 1), _createElementVNode("div", _hoisted_103, _toDisplayString(_ctx.$t('AppPage.Link2')), 1)]),
    _: 1
  }, 8, ["show"])], 512), [[_vShow, $data.showMain]])]);
}