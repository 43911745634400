import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import InputField from './InputField.vue';
import Loading from './loading.vue';
import FapiaoFAQ from './FapiaoFAQ.vue';
const __default__ = {
  setup() {
    const activeOrders = ref([0]);
    const activeInform = ref([0]);
    return {
      activeOrders,
      activeInform
    };
  },
  components: {
    InputField,
    Loading,
    FapiaoFAQ
  },
  inject: ['reload'],
  data() {
    return {
      fapiaoAmount: '',
      invoiceType: '',
      invoiceTypeVal: '',
      commonwidth: document.documentElement.clientWidth - 63 + 'px',
      qywidth: document.documentElement.clientWidth - 28 + 'px',
      spanwidth: document.documentElement.clientWidth - 80 + 'px',
      showFail: false,
      FailErrMsg: '',
      showqusBottom: false,
      showysBottom: false,
      seekpjedown: true,
      seekpjeup: false,
      searchbtn: 'searchbtn searchbtnBgdnon systemfont',
      seekpxxdown1: true,
      seekpxxup1: false,
      seekpxxdown2: true,
      seekpxxup2: false,
      seespsj: true,
      seeskyx: false,
      languagecn: 'language_cn systemfont',
      languageen: 'language_en systemfont',
      closeyes: 'closebtny btnyes systemfont',
      closecancel: 'closebtny btncancel systemfont',
      kpinform: this.$store.state.kpinform,
      sendInform: this.$store.state.receive,
      final: "",
      sendmethod: false,
      gfmcqysty: "frame van_kpborder systemfont",
      gfmcsty: "frame van_kpborder systemfont",
      nsrsbhsty: "frame van_kpborder systemfont",
      gsdzsty: "frame van_kpborder systemfont",
      khyhsty: "frame van_kpborder systemfont",
      dhhmsty: "frame van_kpborder systemfont",
      yhzhsty: "frame van_kpborder systemfont",
      fpbzsty: "frame van_kpborder systemfont",
      grsjhsty: "frame van_kpborder systemfont",
      gryxsty: "frame van_kpborder systemfont",
      chosenzh: 1,
      qybtn: "btnblue choseonebtn systemfont",
      grbtn: "btnclack choseonebtn systemfont",
      qybtnen: "btnblue choseonebtnen systemfont",
      grbtnen: "btnclack choseonebtnen systemfont",
      sjbtn: "btnblue choseonebtn systemfont",
      yxbtn: "btnclack choseonebtn systemfont",
      isPrivate: false,
      isSubmit: true,
      showMain: true,
      showLoading: false,
      orders: [],
      showdetailcss: "systemfont fontweight3 fontsize14 colorblue line20 aligncenter",
      dollorcss: "systemfont fontweight3 fontsize12 colorsmblack line18 aligncenter",
      price2css: "systemfont fontweight6 fontsize10 colorsmblack line16 aligncenter",
      price1css: "systemfont fontweight6 fontsize14 colorsmblack line20 aligncenter",
      titlecss: "innertext floatleft systemfont",
      valuecss: "innertext floatright systemfont",
      nsrsbhpla: this.$t('AppPage.inputnumber'),
      nsrsbhplai: this.$t('AppPage.inputnumberi'),
      nsrsbhplax: this.$t('AppPage.inputnumberx'),
      gryxph: this.$t('AppPage.emailaddress'),
      gryxphi: this.$t('AppPage.emailaddressi'),
      grsjhph: this.$t('AppPage.phonenumber'),
      grsjhphi: this.$t('AppPage.phonenumberi'),
      gfmcph: this.$t('AppPage.inputtitle'),
      gfmcphi: this.$t('AppPage.inputtitlei'),
      gsdzph: this.$t('AppPage.inputaddress'),
      gsdzphi: this.$t('AppPage.inputaddressi'),
      khyhph: this.$t('AppPage.inputbankname'),
      khyhphi: this.$t('AppPage.inputbanknamei'),
      dhhmph: this.$t('AppPage.inputtelephone'),
      dhhmphi: this.$t('AppPage.inputtelephonei'),
      yhzhph: this.$t('AppPage.inputaccount'),
      yhzhphi: this.$t('AppPage.inputaccounti'),
      fpbzph: this.$t('AppPage.inputRemarks'),
      fpbzphi: this.$t('AppPage.inputRemarksi'),
      fplxph: this.$t('AppPage.chosetype'),
      fplxphi: this.$t('AppPage.chosetypei'),
      gfmcmsg: false,
      gfmcmsgn: false,
      gfmcgrmsg: false,
      gfmcgrmsgn: false,
      nsrsbhmsg: false,
      nsrsbhmsgn: false,
      gsdzmsg: false,
      khyhmsg: false,
      dhhmmsg: false,
      yhzhmsg: false,
      fpbzmsg: false,
      grsjhmsg: false,
      gryxmsg: false,
      changeIndexqy: 0,
      changeIndexgr: 0,
      chosegr: false,
      choseqy: true,
      fieldbr: '',
      inputingstyle: 'frame van_kpbordering systemfont',
      inputstyle: 'frame van_kpborder systemfont',
      inputerrstyle: 'frame van_kpbordererr systemfont',
      clear: '',
      weixinopen: '',
      customerList: [],
      showPopover: false,
      timer: null,
      isSHDR: this.$store.state.isSHDR,
      changeIndexphone: 0,
      changeIndexemail: 0,
      buyerNameqyval: '',
      customerListOption: [],
      invoiceTypeOption: [],
      fplxBorder: 'grayborder',
      shdrBorder: 'grayborder',
      banwords: []
    };
  },
  // computed: {
  //     emailStatus() {
  //         return this.$store.state.emailStatus;
  //     },
  //     mobileStatus() {
  //         return this.$store.state.mobileStatus;
  //     },
  // },
  watch: {
    // emailStatus: {
    //     handler: function (newValue) {
    //         if (newValue == true) {
    //             this.gryxmsg = false;
    //         }
    //     }
    // },
    // mobileStatus: {
    //     handler: function (newValue) {
    //         if (newValue == true) {
    //             this.grsjhmsg = false;
    //         }
    //     }
    // },
    isPrivate: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          if (newValue == true) {
            this.searchbtn = 'searchbtn searchbtnBgd systemfont';
            this.isSubmit = false;
          } else {
            this.searchbtn = 'searchbtn searchbtnBgdnon systemfont';
            this.isSubmit = true;
          }
        } else {
          this.searchbtn = 'searchbtn searchbtnBgdnon systemfont';
          this.isSubmit = true;
        }
      }
    },
    "kpinform.buyerNameqy": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchGfmcqy(newValue);
      }
    },
    "kpinform.buyerNamegr": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchGfmcgr(newValue);
      }
    },
    "kpinform.taxNo": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchNsrsbh(newValue);
      }
    },
    "kpinform.compAddress": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchGsdz(newValue);
      }
    },
    "kpinform.bank": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchKhyh(newValue);
      }
    },
    "kpinform.phone": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchDhhm(newValue);
      }
    },
    "kpinform.BankAddress": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchYhzh(newValue);
      }
    },
    "kpinform.memoqy": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchFpbzqy(newValue);
      }
    },
    "kpinform.memogr": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchFpbzgr(newValue);
      }
    },
    "sendInform.sendPhone": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchGrsjh(newValue);
      }
    },
    "sendInform.sendEmail": {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchGryx(newValue);
      }
    }
  },
  mounted() {
    this.$i18n.locale = this.$store.state.lang;
    if (this.$store.state.lang == 'en') {
      this.languagecn = 'language_cn langunchosen systemfont';
      this.languageen = 'language_en langchosen systemfont';
      // this.fieldbr = 'fieldbren';
      this.clear = 'en';
    } else {
      this.languagecn = 'language_cn langchosen systemfont';
      this.languageen = 'language_en langunchosen systemfont';
      // this.fieldbr = 'fieldbrcn';
      this.clear = 'zh';
    }
    this.orders = this.$store.state.orders;
    this.fapiaoAmount = this.$store.state.fapiaoAmount;
    // this.invoiceType = this.$store.state.invoiceType;
    this.changeIndexqy = 0;
    this.chosegr = false;
    this.choseqy = true;
    // this.chosenzh = 1;
    this.kpinform = this.$store.state.kpinform;
    this.sendInform = this.$store.state.receive;
    if (this.kpinform.buyerType == 1) {
      this.chosenzh = 0;
      this.choseqy = false;
      this.chosegr = true;
    }
    if (this.kpinform.buyerNameqy && this.kpinform.buyerType == 0 && !this.isSHDR) {
      this.changeIndexqy = 1;
      document.getElementById("buyerNameqy").setAttribute("placeholder", "");
    }
    if (this.sendInform.sendType == 1) {
      this.yxbtn = 'btnblue choseonebtn systemfont';
      this.sjbtn = 'btnclack choseonebtn systemfont';
      this.seeskyx = true;
      this.seespsj = false;
    }
    this.invoiceTypeOption = [];
    for (var invoiceType of this.$store.state.invoiceType) {
      this.invoiceTypeOption.push({
        text: this.getItext(invoiceType),
        value: invoiceType
      });
    }
    if (this.$store.state.invoiceRequestInvoiceType) {
      this.invoiceTypeVal = this.$store.state.invoiceRequestInvoiceType;
    } else {
      this.invoiceTypeVal = this.$store.state.invoiceType[0];
    }
    this.invoiceType = this.getItext(this.invoiceTypeVal);
    if (this.isSHDR) {
      this.buyerNameqyval = this.kpinform.taxNo;
      this.customerListOption = [];
      for (var costomer of this.$store.state.costomerList) {
        this.customerListOption.push({
          text: costomer.SellerName,
          value: costomer.SellerTaxNumber
        });
      }
    }
    this.getBanWords();
  },
  methods: {
    clearChinese() {
      this.sendInform.sendPhone = this.sendInform.sendPhone.replace(/[^\x00-\xff]/ig, '');
    },
    clearBlank() {
      this.sendInform.sendEmail = this.sendInform.sendEmail.replace(/\s+/g, '');
    },
    getItext(val) {
      if (val == 'EC') {
        return this.$t('AppPage.Valueaddec');
      }
      if (val == 'BEC') {
        return this.$t('AppPage.Valueaddbec');
      }
      if (val == 'BES') {
        return this.$t('AppPage.Valueaddbes');
      }
    },
    clickChangeQy() {
      var finalCustomer = {};
      for (var costomer of this.$store.state.costomerList) {
        if (costomer.SellerTaxNumber == this.buyerNameqyval) {
          finalCustomer = costomer;
        }
      }
      this.kpinform.buyerNameqy = finalCustomer.SellerName;
      this.kpinform.taxNo = finalCustomer.SellerTaxNumber;
      if (this.invoiceTypeVal == 'EC') {
        this.kpinform.compAddress = finalCustomer.SellerAddress;
        this.kpinform.bank = finalCustomer.BankName;
        this.kpinform.phone = finalCustomer.SellerTel;
        this.kpinform.BankAddress = finalCustomer.BankAccount;
      }
      this.$store.commit('setKpinform', this.kpinform);
    },
    clickChangeLx() {
      this.invoiceType = this.getItext(this.invoiceTypeVal);
    },
    changeOpenBorder() {
      this.gfmcmsg = false;
      this.gfmcmsgn = false;
      this.shdrBorder = 'blueborder';
    },
    changeCloseBorder() {
      this.shdrBorder = 'grayborder';
      this.onWatchGfmcqy(this.kpinform.buyerNameqy);
    },
    changeOpenBorderLx() {
      this.fplxBorder = 'blueborder';
    },
    changeCloseBorderLx() {
      this.fplxBorder = 'grayborder';
    },
    keySign(title) {
      let s = this.kpinform.buyerNameqy; // 搜索框的值(您要标红的关键字)
      var str = title; // 列表标题(原文本)// 去除中间空格且字符之间用逗号隔开
      let inputvalue = s.replace(/\ +/g, ","); // 把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(","); // 把字符串分割转换成数组(方便截取)// 判断文本段落(原文本)是否为空
      if (str && str != "") {
        // 遍历分割后的字符串
        keyWordArr2.forEach(e => {
          let regStr = "" + `(${e})`;
          let reg = new RegExp(regStr, "g"); // 如果匹配成功则抛出关键字DOM// TIPS: 这块您可以自定义标签可根据您的需求自定义样式
          str = str.replace(reg, '<span style="font-weight:600;font-size:16px;line-height:22px;">' + e + "</span>"); // 改变搜索关键字颜色为红色
        });
      }
      return str;
    },
    // onClickCustomer(customer) {
    //     this.showPopover = false
    //     var dm = ''
    //     var yh = ''
    //     var ss = customer.Account.split('行')
    //     if (ss && ss.length >= 2) {
    //         var dm = ss[ss.length-1]
    //         var yh = ss.slice(0, ss.length-1).join('行') + '行'
    //     }
    //     this.kpinform.buyerNameqy = customer.Name
    //     this.kpinform.taxNo = customer.TaxNumber
    //     this.kpinform.compAddress = customer.Address
    //     this.kpinform.bank = yh
    //     this.kpinform.phone = customer.Tel
    //     this.kpinform.BankAddress = dm
    // },
    // onClickSHDR(customer) {
    //     this.showPopover = false
    //     this.changeIndexqy = 1;
    //     this.kpinform.buyerNameqy = customer.SellerName
    //     this.kpinform.taxNo = customer.SellerTaxNumber
    //     this.kpinform.compAddress = customer.SellerAddress
    //     this.kpinform.bank = customer.BankName
    //     this.kpinform.phone = customer.SellerTel
    //     this.kpinform.BankAddress = customer.BankAccount
    //     this.showPopover = false
    // },
    onShowPolicy() {
      if (this.clear == 'en') {
        window.open("https://www.shanghaidisneyresort.com/en/privacy-policy");
      } else {
        window.open("https://www.shanghaidisneyresort.com/privacy-policy");
      }
    },
    getByteLen(val) {
      var len = 0;
      for (var i = 0; i < val.length; i++) {
        var a = val.charAt(i);
        if (a.match(/[^\x00-\xff]/ig) != null) {
          len += 2;
        } else {
          len += 1;
        }
      }
      return len;
    },
    containsPhoneNumber(str) {
      const phoneRegex = /\d{11}/;
      return phoneRegex.test(str);
    },
    getBanWords() {
      this.axios.post('cdapply/GetBanWords/').then(response => {
        this.banwords = response.data.data;
      });
    },
    containsBanWords(val) {
      for (var banword of this.banwords) {
        if (val.indexOf(banword) != -1) {
          return true;
        }
      }
    },
    onWatchNsrsbh(val) {
      var numReg = /^[0-9]*$/;
      var mixReg = /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]*$/;
      // var expReg = /^[^IOZSV]*$/;
      var expReg = /^[^O]*$/;
      var numRe = new RegExp(numReg);
      var mixRe = new RegExp(mixReg);
      var expRe = new RegExp(expReg);
      if (expRe.test(val) && (numRe.test(val) || mixRe.test(val)) && (val.length == 15 || val.length == 18 || val.length == 20)) {
        if (document.activeElement == document.getElementById("taxNo")) {
          this.nsrsbhsty = this.inputingstyle;
        } else {
          this.nsrsbhsty = this.inputstyle;
        }
        this.$store.commit('setTaxNoStatus', true);
        this.nsrsbhmsg = false;
        this.nsrsbhmsgn = false;
      } else {
        if (val) {
          this.$store.commit('setTaxNoStatus', false);
          this.nsrsbhsty = this.inputerrstyle;
          this.nsrsbhmsg = false;
          this.nsrsbhmsgn = true;
        } else {
          this.$store.commit('setTaxNoStatus', true);
          if (document.activeElement == document.getElementById("taxNo")) {
            this.nsrsbhsty = this.inputingstyle;
          } else {
            this.nsrsbhsty = this.inputstyle;
          }
          this.nsrsbhmsg = false;
          this.nsrsbhmsgn = false;
        }
      }
    },
    onWatchNsrsbhSub(val) {
      var numReg = /^[0-9]*$/;
      var mixReg = /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]*$/;
      // var expReg = /^[^IOZSV]*$/;
      var expReg = /^[^O]*$/;
      var numRe = new RegExp(numReg);
      var mixRe = new RegExp(mixReg);
      var expRe = new RegExp(expReg);
      if (val) {
        if (expRe.test(val) && (numRe.test(val) || mixRe.test(val)) && (val.length == 15 || val.length == 18 || val.length == 20)) {
          this.nsrsbhsty = this.inputstyle;
          this.nsrsbhmsg = false;
          this.nsrsbhmsgn = false;
          return true;
        } else {
          this.nsrsbhsty = this.inputerrstyle;
          this.nsrsbhmsg = false;
          this.nsrsbhmsgn = true;
          return false;
        }
      }
      this.nsrsbhsty = this.inputerrstyle;
      this.nsrsbhmsg = true;
      this.nsrsbhmsgn = false;
      return false;
    },
    onWatchGfmcqy(val) {
      // this.showPopover = false
      if (val && this.kpinform.buyerType == 0 && !this.isSHDR) {
        this.changeIndexqy = 1;
        document.getElementById("buyerNameqy").setAttribute("placeholder", "");
      }
      var numReg = /^[^@&*]*$/;
      var numRe = new RegExp(numReg);
      if (!this.containsPhoneNumber(val) && !this.containsBanWords(val)) {
        if (val) {
          if (this.getByteLen(val) > 100) {
            this.$store.commit('setBuyerNameqyStatus', false);
            this.gfmcqysty = this.inputerrstyle;
            this.shdrBorder = 'redborder';
            this.gfmcmsg = false;
            this.gfmcmsgn = true;
          } else {
            if (!this.isSHDR) {
              this.$store.commit('setBuyerNameqyStatus', true);
              if (document.activeElement == document.getElementById("buyerNameqy")) {
                this.gfmcqysty = this.inputingstyle;
              } else {
                this.gfmcqysty = this.inputstyle;
              }
              this.gfmcmsg = false;
              this.gfmcmsgn = false;
            }
          }
        } else {
          if (!this.isSHDR) {
            this.$store.commit('setBuyerNameqyStatus', true);
            if (document.activeElement == document.getElementById("buyerNameqy")) {
              this.gfmcqysty = this.inputingstyle;
            } else {
              this.gfmcqysty = this.inputstyle;
            }
            this.gfmcmsg = false;
            this.gfmcmsgn = false;
          }
        }
      } else {
        this.$store.commit('setBuyerNameqyStatus', false);
        this.gfmcqysty = this.inputerrstyle;
        this.shdrBorder = 'redborder';
        this.gfmcmsg = false;
        this.gfmcmsgn = true;
      }
    },
    getCustomer(customer) {
      this.axios.post('cdapply/GetCustomer/', {
        "customer": customer
      }).then(response => {
        this.customerList = response.data.data;
        this.showPopover = true;
      });
    },
    onWatchGfmcgr(val) {
      if (val && this.kpinform.buyerType == 1) {
        this.changeIndexgr = 1;
        document.getElementById("buyerNamegr").setAttribute("placeholder", "");
      }
      var numReg = /[@&*]/;
      if (this.invoiceTypeVal == 'BEC' || this.invoiceTypeVal == 'BES') {
        numReg = /[^\u4e00-\u9fa5\(\)（）a-zA-Z0-9]+/g;
      }
      var numRe = new RegExp(numReg);
      if (this.containsPhoneNumber(val) || this.containsBanWords(val)) {
        this.$store.commit('setBuyerNamegrStatus', false);
        this.gfmcsty = this.inputerrstyle;
        this.gfmcgrmsg = false;
        this.gfmcgrmsgn = true;
      } else {
        if (val && this.getByteLen(val) > 100) {
          this.$store.commit('setBuyerNamegrStatus', false);
          this.gfmcsty = this.inputerrstyle;
          this.gfmcgrmsg = false;
          this.gfmcgrmsgn = true;
        } else {
          this.$store.commit('setBuyerNamegrStatus', true);
          if (document.activeElement == document.getElementById("buyerNamegr")) {
            this.gfmcsty = this.inputingstyle;
          } else {
            this.gfmcsty = this.inputstyle;
          }
          this.gfmcgrmsg = false;
          this.gfmcgrmsgn = false;
        }
      }
    },
    onWatchGfmcSub(val) {
      var numReg = /[@&*]/;
      if ((this.invoiceTypeVal == 'BEC' || this.invoiceTypeVal == 'BES') && this.kpinform.buyerType == 1) {
        numReg = /[^\u4e00-\u9fa5\(\)（）a-zA-Z0-9]+/g;
      }
      var numRe = new RegExp(numReg);
      if (val) {
        // this.changeIndex = 1;
        // document.getElementById("buyerName").setAttribute("placeholder", "");
        if (this.containsPhoneNumber(val) || this.containsBanWords(val)) {
          if (this.kpinform.buyerType == 1) {
            this.gfmcsty = 'frame van_kpbordererr systemfont';
          } else {
            this.gfmcqysty = 'frame van_kpbordererr systemfont';
            this.shdrBorder = 'redborder';
          }
          this.gfmcmsg = false;
          this.gfmcmsgn = true;
          this.gfmcgrmsg = false;
          this.gfmcgrmsgn = true;
          return false;
        } else {
          if (val && this.getByteLen(val) > 100) {
            if (this.kpinform.buyerType == 1) {
              this.gfmcsty = 'frame van_kpbordererr systemfont';
            } else {
              this.gfmcqysty = 'frame van_kpbordererr systemfont';
              this.shdrBorder = 'redborder';
            }
            this.gfmcmsg = false;
            this.gfmcmsgn = true;
            this.gfmcgrmsg = false;
            this.gfmcgrmsgn = true;
            return false;
          } else {
            this.gfmcsty = 'frame van_kpborder systemfont';
            this.gfmcqysty = 'frame van_kpborder systemfont';
            this.gfmcmsg = false;
            this.gfmcmsgn = false;
            this.gfmcgrmsg = false;
            this.gfmcgrmsgn = false;
            return true;
          }
        }
      }
      if (this.kpinform.buyerType == 1) {
        this.gfmcsty = 'frame van_kpbordererr systemfont';
      } else {
        this.gfmcqysty = 'frame van_kpbordererr systemfont';
        this.shdrBorder = 'redborder';
      }
      this.gfmcmsgn = false;
      this.gfmcmsg = true;
      this.gfmcgrmsg = true;
      this.gfmcgrmsgn = false;
      return false;
    },
    onWatchGsdz(val) {
      var numReg = /^[^@&*]*$/;
      var numRe = new RegExp(numReg);
      if (val) {
        if (numRe.test(val) && this.getByteLen(val) <= 80) {
          if (document.activeElement == document.getElementById("compAddress")) {
            this.gsdzsty = this.inputingstyle;
          } else {
            this.gsdzsty = this.inputstyle;
          }
          this.$store.commit('setCompAddressStatus', true);
          this.gsdzmsg = false;
          return true;
        } else {
          this.$store.commit('setCompAddressStatus', false);
          this.gsdzsty = this.inputerrstyle;
          this.gsdzmsg = true;
          return false;
        }
      } else {
        this.$store.commit('setCompAddressStatus', true);
        if (document.activeElement == document.getElementById("compAddress")) {
          this.gsdzsty = this.inputingstyle;
        } else {
          this.gsdzsty = this.inputstyle;
        }
        this.gsdzmsg = false;
        return true;
      }
    },
    onWatchKhyh(val) {
      var numReg = /^[^@&*]*$/;
      var numRe = new RegExp(numReg);
      if (val) {
        if (numRe.test(val) && this.getByteLen(val) <= 80) {
          this.$store.commit('setBankStatus', true);
          if (document.activeElement == document.getElementById("bank")) {
            this.khyhsty = this.inputingstyle;
          } else {
            this.khyhsty = this.inputstyle;
          }
          this.khyhmsg = false;
          return true;
        } else {
          this.$store.commit('setBankStatus', false);
          this.khyhsty = this.inputerrstyle;
          this.khyhmsg = true;
          return false;
        }
      } else {
        this.$store.commit('setBankStatus', true);
        if (document.activeElement == document.getElementById("bank")) {
          this.khyhsty = this.inputingstyle;
        } else {
          this.khyhsty = this.inputstyle;
        }
        this.khyhmsg = false;
        return true;
      }
    },
    onWatchDhhm(val) {
      var numReg = /^[^@&*]*$/;
      var numRe = new RegExp(numReg);
      if (val) {
        if (numRe.test(val) && this.getByteLen(val) <= 20) {
          this.$store.commit('setPhoneStatus', true);
          if (document.activeElement == document.getElementById("phone")) {
            this.dhhmsty = this.inputingstyle;
          } else {
            this.dhhmsty = this.inputstyle;
          }
          this.dhhmmsg = false;
          return true;
        } else {
          this.$store.commit('setPhoneStatus', false);
          this.dhhmsty = this.inputerrstyle;
          this.dhhmmsg = true;
          return false;
        }
      } else {
        this.$store.commit('setPhoneStatus', true);
        if (document.activeElement == document.getElementById("phone")) {
          this.dhhmsty = this.inputingstyle;
        } else {
          this.dhhmsty = this.inputstyle;
        }
        this.dhhmmsg = false;
        return true;
      }
    },
    onWatchYhzh(val) {
      var numReg = /^[^@&*]*$/;
      var numRe = new RegExp(numReg);
      if (val) {
        if (numRe.test(val) && this.getByteLen(val) <= 20) {
          this.$store.commit('setBankAddressStatus', true);
          if (document.activeElement == document.getElementById("BankAddress")) {
            this.yhzhsty = this.inputingstyle;
          } else {
            this.yhzhsty = this.inputstyle;
          }
          this.yhzhmsg = false;
          return true;
        } else {
          this.$store.commit('setBankAddressStatus', false);
          this.yhzhsty = this.inputerrstyle;
          this.yhzhmsg = true;
          return false;
        }
      } else {
        this.$store.commit('setBankAddressStatus', true);
        if (document.activeElement == document.getElementById("BankAddress")) {
          this.yhzhsty = this.inputingstyle;
        } else {
          this.yhzhsty = this.inputstyle;
        }
        this.yhzhmsg = false;
        return true;
      }
    },
    onWatchFpbzqy(val) {
      var numReg = /^[^@&*]*$/;
      var numRe = new RegExp(numReg);
      if (val) {
        if (numRe.test(val) && this.getByteLen(val) <= 100) {
          this.$store.commit('setMemoqyStatus', true);
          if (document.activeElement == document.getElementById("memoqy")) {
            this.fpbzsty = this.inputingstyle;
          } else {
            this.fpbzsty = this.inputstyle;
          }
          this.fpbzmsg = false;
          return true;
        } else {
          this.$store.commit('setMemoqyStatus', false);
          this.fpbzsty = this.inputerrstyle;
          this.fpbzmsg = true;
          return false;
        }
      } else {
        this.$store.commit('setMemoqyStatus', true);
        if (document.activeElement == document.getElementById("memoqy")) {
          this.fpbzsty = this.inputingstyle;
        } else {
          this.fpbzsty = this.inputstyle;
        }
        this.fpbzmsg = false;
        return true;
      }
    },
    onWatchFpbzgr(val) {
      var numReg = /^[^@&*]*$/;
      var numRe = new RegExp(numReg);
      if (val) {
        if (numRe.test(val) && this.getByteLen(val) <= 100) {
          this.$store.commit('setMemogrStatus', true);
          if (document.activeElement == document.getElementById("memogr")) {
            this.fpbzsty = this.inputingstyle;
          } else {
            this.fpbzsty = this.inputstyle;
          }
          this.fpbzmsg = false;
          return true;
        } else {
          this.$store.commit('setMemogrStatus', false);
          this.fpbzsty = this.inputerrstyle;
          this.fpbzmsg = true;
          return false;
        }
      } else {
        this.$store.commit('setMemogrStatus', true);
        if (document.activeElement == document.getElementById("memogr")) {
          this.fpbzsty = this.inputingstyle;
        } else {
          this.fpbzsty = this.inputstyle;
        }
        this.fpbzmsg = false;
        return true;
      }
    },
    focusedphone() {
      this.changeIndexphone = 1;
      document.getElementById("sendPhoneid").setAttribute("placeholder", "");
      if (!this.sendInform.sendPhone) {
        document.getElementById("sendPhoneid").setAttribute("placeholder", this.grsjhph);
      }
      this.grsjhsty = 'frame van_kpbordering systemfont';
      this.grsjhmsg = false;
    },
    unfocusedphone() {
      document.getElementById("sendPhoneid").setAttribute("placeholder", "");
      if (this.sendInform.sendPhone) {
        this.changeIndexphone = 1;
      } else {
        this.changeIndexphone = 0;
      }
      var numReg = /^1\d{10}$/;
      var numRe = new RegExp(numReg);
      if (numRe.test(this.sendInform.sendPhone) && this.sendInform.sendPhone.length == 11) {
        this.grsjhsty = 'frame van_kpborder systemfont';
        this.grsjhmsg = false;
      } else {
        if (this.sendInform.sendPhone) {
          this.grsjhsty = 'frame van_kpbordererr systemfont';
          this.grsjhmsg = true;
        } else {
          this.grsjhsty = 'frame van_kpborder systemfont';
          this.grsjhmsg = false;
        }
      }
    },
    onWatchGrsjh(val) {
      this.$store.commit('setReceive', this.sendInform);
      var numReg = /^1\d{10}$/;
      var numRe = new RegExp(numReg);
      this.final = this.sendInform.sendPhone;
      if (val) {
        this.changeIndexphone = 1;
        document.getElementById("sendPhoneid").setAttribute("placeholder", "");
      } else {
        document.getElementById("sendPhoneid").setAttribute("placeholder", this.grsjhph);
      }
      this.grsjhmsg = false;
      if (numRe.test(val) && val.length == 11) {
        this.sendmethod = true;
      } else {
        if (val) {
          this.sendmethod = true;
        } else {
          this.sendmethod = false;
        }
      }
    },
    onWatchGrsjhSub(val) {
      var numReg = /^1\d{10}$/;
      var numRe = new RegExp(numReg);
      this.final = this.sendInform.sendPhone;
      if (numRe.test(val) && val.length == 11) {
        this.grsjhsty = 'frame van_kpborder systemfont';
        this.grsjhmsg = false;
        this.sendmethod = true;
        return true;
      } else {
        this.grsjhsty = 'frame van_kpbordererr systemfont';
        this.grsjhmsg = true;
        if (val) {
          this.sendmethod = true;
        } else {
          this.sendmethod = false;
        }
        return false;
      }
    },
    focusedemail() {
      this.changeIndexemail = 1;
      document.getElementById("sendEmailid").setAttribute("placeholder", "");
      if (!this.sendInform.sendEmail) {
        document.getElementById("sendEmailid").setAttribute("placeholder", this.gryxph);
      }
      this.gryxsty = 'frame van_kpbordering systemfont';
      this.gryxmsg = false;
    },
    unfocusedemail() {
      document.getElementById("sendEmailid").setAttribute("placeholder", "");
      if (this.sendInform.sendEmail) {
        this.changeIndexemail = 1;
      } else {
        this.changeIndexemail = 0;
      }
      var numReg = "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      // var numReg = "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      var numRe = new RegExp(numReg);
      if (numRe.test(this.sendInform.sendEmail)) {
        this.gryxsty = 'frame van_kpborder systemfont';
        this.gryxmsg = false;
      } else {
        if (this.sendInform.sendEmail) {
          this.gryxsty = 'frame van_kpbordererr systemfont';
          this.gryxmsg = true;
        } else {
          this.gryxsty = 'frame van_kpborder systemfont';
          this.gryxmsg = false;
        }
      }
    },
    onWatchGryx(val) {
      this.$store.commit('setReceive', this.sendInform);
      var numReg = "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      //var numReg = "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      // var numReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      var numRe = new RegExp(numReg);
      this.final = this.sendInform.sendEmail;
      this.gryxmsg = false;
      if (numRe.test(val)) {
        this.sendmethod = true;
      } else {
        if (val) {
          this.sendmethod = true;
        } else {
          this.sendmethod = false;
        }
      }
    },
    onWatchGryxSub(val) {
      var numReg = "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      var numRe = new RegExp(numReg);
      this.final = this.sendInform.sendEmail;
      if (numRe.test(val)) {
        this.gryxsty = 'frame van_kpborder systemfont';
        this.gryxmsg = false;
        this.sendmethod = true;
        return true;
      } else {
        this.gryxsty = 'frame van_kpbordererr systemfont';
        this.gryxmsg = true;
        if (val) {
          this.sendmethod = true;
        } else {
          this.sendmethod = false;
        }
        return false;
      }
    },
    onclickdown() {
      this.seekpjedown = false;
      this.seekpjeup = true;
      this.$refs.collapse.toggleAll(true);
    },
    onclickup() {
      this.seekpjedown = true;
      this.seekpjeup = false;
      this.$refs.collapse.toggleAll(false);
    },
    onSwitchUser(param) {
      this.seekpxxdown1 = true;
      this.seekpxxup1 = false;
      this.seekpxxdown2 = true;
      this.seekpxxup2 = false;
      if (param == 'qy') {
        this.choseqy = true;
        this.chosegr = false;
        // this.grbtn = 'btnclack choseonebtn systemfont';
        // this.qybtn = 'btnblue choseonebtn systemfont';
        this.chosenzh = 1;
        this.kpinform.buyerType = 0;
        if (this.seekpxxdown1 == false) {
          this.onWatchGsdz(this.kpinform.compAddress);
          this.onWatchKhyh(this.kpinform.bank);
          this.onWatchDhhm(this.kpinform.phone);
          this.onWatchYhzh(this.kpinform.BankAddress);
        }
        this.onWatchNsrsbh(this.kpinform.taxNo);
        this.onWatchGfmcqy(this.kpinform.buyerNameqy);
      } else if (param == 'gr') {
        this.chosegr = true;
        this.choseqy = false;
        // this.grbtn = 'btnblue choseonebtn systemfont';
        // this.qybtn = 'btnclack choseonebtn systemfont';
        this.chosenzh = 0;
        this.kpinform.buyerType = 1;
        this.onWatchGfmcgr(this.kpinform.buyerNamegr);
      }
      if (this.invoiceTypeVal == 'EC') {
        this.$refs.collapse1.toggleAll(false);
        this.$refs.collapse2.toggleAll(false);
      }
      this.$store.commit('setKpinform', this.kpinform);
    },
    onSwitchShou(param) {
      this.grsjhmsg = false;
      this.gryxmsg = false;
      this.grsjhsty = 'frame van_kpborder systemfont';
      this.gryxsty = 'frame van_kpborder systemfont';
      if (param == 'sj') {
        this.sendInform.sendType = 0;
        this.sendInform.sendEmail = '';
        this.yxbtn = 'btnclack choseonebtn systemfont';
        this.sjbtn = 'btnblue choseonebtn systemfont';
        this.seespsj = true;
        this.seeskyx = false;
        this.final = this.sendInform.sendPhone;
        this.changeIndexphone = 0;
        // this.onWatchGrsjh(this.sendInform.sendPhone);
      } else if (param == 'yx') {
        this.sendInform.sendType = 1;
        this.sendInform.sendPhone = '';
        this.yxbtn = 'btnblue choseonebtn systemfont';
        this.sjbtn = 'btnclack choseonebtn systemfont';
        this.seespsj = false;
        this.seeskyx = true;
        this.final = this.sendInform.sendEmail;
        this.changeIndexemail = 0;
        // this.onWatchGryx(this.sendInform.sendEmail);
      }
      this.$store.commit('setReceive', this.sendInform);
    },
    onfpxxup() {
      this.fpbzmsg = false;
      this.seekpxxdown2 = true;
      this.seekpxxup2 = false;
      this.seekpxxdown1 = true;
      this.seekpxxup1 = false;
      if (this.kpinform.buyerType == 1) {
        // this.seekpxxdown2 = true;
        // this.seekpxxup2 = false;
        // this.fpbzmsg = false;
        // this.$refs.collapse1.toggleAll(false);
        // } else {
        // this.seekpxxdown1 = true;
        // this.seekpxxup1 = false;
        this.gsdzmsg = false;
        this.khyhmsg = false;
        this.dhhmmsg = false;
        this.yhzhmsg = false;
        // this.$refs.collapse2.toggleAll(false);
      }
      if (this.invoiceTypeVal == 'EC') {
        this.$refs.collapse1.toggleAll(false);
        this.$refs.collapse2.toggleAll(false);
      }
    },
    onfpxxdown() {
      this.seekpxxdown1 = false;
      this.seekpxxup1 = true;
      this.seekpxxdown2 = false;
      this.seekpxxup2 = true;
      if (this.kpinform.buyerType == 0) {
        // this.seekpxxdown1 = false;
        // this.seekpxxup1 = true;
        this.onWatchNsrsbh(this.kpinform.taxNo);
        this.onWatchGsdz(this.kpinform.compAddress);
        this.onWatchKhyh(this.kpinform.bank);
        this.onWatchDhhm(this.kpinform.phone);
        this.onWatchYhzh(this.kpinform.BankAddress);
        if (this.invoiceTypeVal == 'EC') {
          this.$refs.collapse2.toggleAll(true);
        }
        this.onWatchFpbzqy(this.kpinform.memoqy);
      } else {
        // this.seekpxxdown2 = false;
        // this.seekpxxup2 = true;
        if (this.invoiceTypeVal == 'EC') {
          this.$refs.collapse1.toggleAll(true);
        }
        this.onWatchFpbzgr(this.kpinform.memogr);
      }
      // this.$refs.collapse1.toggleAll(true);
      // this.$refs.collapse2.toggleAll(true);
    },
    onClickSubmit() {
      var kpApprove = 0;
      // 开票信息校验
      if (this.kpinform.buyerType == 1) {
        if (this.onWatchGfmcSub(this.kpinform.buyerNamegr) && this.onWatchFpbzgr(this.kpinform.memogr)) {
          kpApprove = kpApprove + 1;
          this.kpinform.memo = this.kpinform.memogr;
          this.kpinform.buyerName = this.kpinform.buyerNamegr;
        }
      } else if (this.kpinform.buyerType == 0) {
        if (this.invoiceTypeVal == 'EC') {
          if (this.onWatchGfmcSub(this.kpinform.buyerNameqy) && this.onWatchNsrsbhSub(this.kpinform.taxNo) && this.onWatchGsdz(this.kpinform.compAddress) && this.onWatchKhyh(this.kpinform.bank) && this.onWatchDhhm(this.kpinform.phone) && this.onWatchYhzh(this.kpinform.BankAddress) && this.onWatchFpbzqy(this.kpinform.memoqy)) {
            kpApprove = kpApprove + 1;
            this.kpinform.memo = this.kpinform.memoqy;
            this.kpinform.buyerName = this.kpinform.buyerNameqy;
          }
        } else if (this.invoiceTypeVal == 'BEC' || this.invoiceTypeVal == 'BES') {
          if (this.onWatchGfmcSub(this.kpinform.buyerNameqy) && this.onWatchNsrsbhSub(this.kpinform.taxNo) && this.onWatchFpbzqy(this.kpinform.memoqy)) {
            kpApprove = kpApprove + 1;
            this.kpinform.memo = this.kpinform.memoqy;
            this.kpinform.buyerName = this.kpinform.buyerNameqy;
          }
        }
      }
      this.$store.commit('setKpinform', this.kpinform);
      // 收票信息校验
      if (this.sendInform.sendType == 1) {
        if (this.onWatchGryxSub(this.sendInform.sendEmail)) {
          kpApprove = kpApprove + 1;
        }
      } else if (this.sendInform.sendType == 0) {
        if (this.onWatchGrsjhSub(this.sendInform.sendPhone)) {
          kpApprove = kpApprove + 1;
        }
      }
      this.$store.commit('setReceive', this.sendInform);
      // 订单转开票申请
      if (kpApprove == 2) {
        this.showMain = false;
        this.showLoading = true;
        this.axios.post('cdapply/SetKpApply/', {
          "orderBillNos": this.$store.state.orderBillNos,
          "isSHDR": this.$store.state.isSHDR,
          "kpinform": this.kpinform,
          "invoiceType": this.invoiceTypeVal
        }).then(response => {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            var resdata = response.data;
            if (resdata.code == 0) {
              this.$store.commit('setInvoiceRequestInvoiceType', this.invoiceTypeVal);
              // this.$store.commit('setInvoiceRequestId', resdata.data['invoiceRequestId']);
              this.$router.push('kpconfirm');
            } else if (resdata.code == '3005') {
              this.showMain = true;
              this.showLoading = false;
              this.FailErrMsg = this.$t('ErrMsgAppPage.Unable');
              this.showFail = true;
            } else {
              this.showMain = true;
              this.showLoading = false;
              this.FailErrMsg = this.$t('ErrMsg.current');
              this.showFail = true;
            }
          }, 500);
        }).catch(err => {
          this.$router.push('systemerr');
        });
      }
    },
    onChangeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('setLang', lang);
      localStorage.setItem('lang', lang);
      this.clear = lang;
      // this.reload();
      this.nsrsbhpla = this.$t('AppPage.inputnumber');
      this.nsrsbhplai = this.$t('AppPage.inputnumberi');
      this.nsrsbhplax = this.$t('AppPage.inputnumberx');
      this.gryxph = this.$t('AppPage.emailaddress');
      this.gryxphi = this.$t('AppPage.emailaddressi');
      this.grsjhph = this.$t('AppPage.phonenumber');
      this.grsjhphi = this.$t('AppPage.phonenumberi');
      this.gfmcph = this.$t('AppPage.inputtitle');
      this.gfmcphi = this.$t('AppPage.inputtitlei');
      this.gsdzph = this.$t('AppPage.inputaddress');
      this.gsdzphi = this.$t('AppPage.inputaddressi');
      this.khyhph = this.$t('AppPage.inputbankname');
      this.khyhphi = this.$t('AppPage.inputbanknamei');
      this.dhhmph = this.$t('AppPage.inputtelephone');
      this.dhhmphi = this.$t('AppPage.inputtelephonei');
      this.yhzhph = this.$t('AppPage.inputaccount');
      this.yhzhphi = this.$t('AppPage.inputaccounti');
      this.fpbzph = this.$t('AppPage.inputRemarks');
      this.fpbzphi = this.$t('AppPage.inputRemarksi');
      if (lang == 'en') {
        this.languagecn = 'language_cn langunchosen systemfont';
        this.languageen = 'language_en langchosen systemfont';
        // this.fieldbr = 'fieldbren';
        document.title = 'Shanghai Disney Resort';
      } else if (lang == 'zh') {
        this.languagecn = 'language_cn langchosen systemfont';
        this.languageen = 'language_en langunchosen systemfont';
        // this.fieldbr = 'fieldbrcn';
        document.title = '上海迪士尼度假区';
      }
    }
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "2c1ca4e0": _ctx.qywidth
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;