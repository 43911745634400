import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "margin-bottom": "32px"
  }
};
const _hoisted_2 = {
  class: "firsttitle systemfont"
};
const _hoisted_3 = {
  class: "InsContent systemfont"
};
const _hoisted_4 = {
  class: "firsttitle systemfont"
};
const _hoisted_5 = {
  class: "qstitle systemfont"
};
const _hoisted_6 = {
  class: "qscontent systemfont"
};
const _hoisted_7 = {
  class: "qstitle systemfont"
};
const _hoisted_8 = {
  class: "qscontent systemfont"
};
const _hoisted_9 = {
  class: "qstitle systemfont"
};
const _hoisted_10 = {
  class: "qscontent systemfont"
};
const _hoisted_11 = {
  class: "qstitle systemfont"
};
const _hoisted_12 = {
  class: "qscontent systemfont"
};
const _hoisted_13 = {
  class: "innercontent systemfont"
};
const _hoisted_14 = {
  class: "innercontent systemfont"
};
const _hoisted_15 = {
  class: "innercontent systemfont"
};
const _hoisted_16 = {
  class: "qstitle systemfont"
};
const _hoisted_17 = {
  class: "qscontent systemfont"
};
const _hoisted_18 = {
  class: "innercontent systemfont"
};
const _hoisted_19 = {
  class: "innercontent systemfont"
};
const _hoisted_20 = {
  class: "qstitle systemfont"
};
const _hoisted_21 = {
  class: "qscontent systemfont"
};
const _hoisted_22 = {
  class: "qstitle systemfont"
};
const _hoisted_23 = {
  class: "qscontent systemfont"
};
const _hoisted_24 = {
  class: "qstitle systemfont"
};
const _hoisted_25 = {
  class: "qscontent systemfont"
};
const _hoisted_26 = {
  class: "innercontent systemfont"
};
const _hoisted_27 = {
  class: "innercontent systemfont"
};
const _hoisted_28 = {
  class: "innercontent systemfont"
};
const _hoisted_29 = {
  class: "qstitle systemfont"
};
const _hoisted_30 = {
  class: "qscontent systemfont"
};
const _hoisted_31 = {
  class: "innercontent systemfont"
};
const _hoisted_32 = {
  class: "innercontent systemfont"
};
const _hoisted_33 = {
  class: "innercontent systemfont"
};
const _hoisted_34 = {
  class: "qstitle systemfont"
};
const _hoisted_35 = {
  class: "qscontent systemfont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('FapiaoFAQ.institle')), 1), _createElementVNode("div", _hoisted_3, [_createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.insconfir')), 1), _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)), _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)), _createTextVNode(" " + _toDisplayString(_ctx.$t('FapiaoFAQ.insconsec')), 1), _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)), _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)), _createTextVNode(" " + _toDisplayString(_ctx.$t('FapiaoFAQ.insconthi')), 1), _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)), _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)), _createTextVNode(" " + _toDisplayString(_ctx.$t('FapiaoFAQ.insconfor')), 1)]), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('FapiaoFAQ.FAQtitle')), 1), _createElementVNode("div", _hoisted_5, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "1.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qsfir')), 1)]), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('FapiaoFAQ.asfir')), 1), _createElementVNode("div", _hoisted_7, [_cache[8] || (_cache[8] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "2.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qssec')), 1)]), _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('FapiaoFAQ.assec')), 1), _createElementVNode("div", _hoisted_9, [_cache[9] || (_cache[9] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "3.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qsthi')), 1)]), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('FapiaoFAQ.asthi')), 1), _createElementVNode("div", _hoisted_11, [_cache[10] || (_cache[10] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "4.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qsfor')), 1)]), _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('FapiaoFAQ.asfor')), 1), _createElementVNode("div", _hoisted_13, [_cache[11] || (_cache[11] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.asforfir')), 1)]), _createElementVNode("div", _hoisted_14, [_cache[12] || (_cache[12] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.asforsec')), 1)]), _createElementVNode("div", _hoisted_15, [_cache[13] || (_cache[13] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.asforthi')), 1)]), _createElementVNode("div", _hoisted_16, [_cache[14] || (_cache[14] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "5.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qsfif')), 1)]), _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('FapiaoFAQ.asfif')), 1), _createElementVNode("div", _hoisted_18, [_cache[15] || (_cache[15] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.asfiffir')), 1)]), _createElementVNode("div", _hoisted_19, [_cache[16] || (_cache[16] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.asfifsec')), 1)]), _createElementVNode("div", _hoisted_20, [_cache[17] || (_cache[17] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "6.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qssix')), 1)]), _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('FapiaoFAQ.assix')), 1), _createElementVNode("div", _hoisted_22, [_cache[18] || (_cache[18] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "7.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qssev')), 1)]), _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('FapiaoFAQ.assev')), 1), _createElementVNode("div", _hoisted_24, [_cache[19] || (_cache[19] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "8.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qseigh')), 1)]), _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('FapiaoFAQ.aseigh')), 1), _createElementVNode("div", _hoisted_26, [_cache[20] || (_cache[20] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.aseighfir')), 1)]), _createElementVNode("div", _hoisted_27, [_cache[21] || (_cache[21] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.aseighsec')), 1)]), _createElementVNode("div", _hoisted_28, [_cache[22] || (_cache[22] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.aseighthi')), 1)]), _createElementVNode("div", _hoisted_29, [_cache[23] || (_cache[23] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "18px"
    }
  }, "9.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qsnine')), 1)]), _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('FapiaoFAQ.asnine')), 1), _createElementVNode("div", _hoisted_31, [_cache[24] || (_cache[24] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.asninefir')), 1)]), _createElementVNode("div", _hoisted_32, [_cache[25] || (_cache[25] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(" " + _toDisplayString(_ctx.$t('FapiaoFAQ.asninesec')), 1), _createElementVNode("a", {
    class: "colorblue",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onChangeClick && $options.onChangeClick(...args))
  }, _toDisplayString(_ctx.$t('FapiaoFAQ.asninethi')), 1), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.asninefor')), 1)]), _createElementVNode("div", _hoisted_33, [_cache[26] || (_cache[26] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "font-size": "18px",
      "left": "40px"
    }
  }, "•", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.asninefif')), 1)]), _createElementVNode("div", _hoisted_34, [_cache[27] || (_cache[27] = _createElementVNode("span", {
    style: {
      "position": "absolute",
      "left": "11px"
    }
  }, "10.", -1)), _createTextVNode(_toDisplayString(_ctx.$t('FapiaoFAQ.qsten')), 1)]), _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('FapiaoFAQ.asten')), 1)]);
}